import './AcknowledgementPage.css'
import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

import { Link } from 'react-router-dom'


const AcknowledgementPage = () => {
  return (
    <>
      <Navbar />
      <div className="ack">
        <img src="/MobileViewResources/assests/ack/img1.jpg" />
        <h1>Check your Inbox!!!</h1>
        <h2>We've subscribed you to the newsletter!</h2>
        <Link to="/">Back to Home</Link>
      </div>
      <Footer />
    </>
  )
}

export default AcknowledgementPage