import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.css";
import Home from "./Home";
import Navbar from "./screens/Navbar/Navbar";
import SignUp from "./screens/SignUp/SignUp";
import SignIn from "./screens/SignIn/SignIn";
import Acknowledge from "./screens/Acknowledge/Acknowledge";
import MobileView from "./MobileView/MobileView"; // Import your MobileView component

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width when the window is resized
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Leave the dependency array empty

  // Render MobileView if the window width is less than 450px, else render the existing content
  return (
    <>
      {windowWidth < 700 ? (
        <MobileView />
      ) : (
        <>
          <Navbar />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/ack" element={<Acknowledge />} />
            </Routes>
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
