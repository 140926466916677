import React, { useEffect, useState } from "react";
import "../Navbar/Navbar.css";
import logo from "../../assets/logo.png";
import menu from "../../assets/menu.png";
import cross from "../../assets/cross.png";

const Navbar = () => {
    const [underlineColor, setUnderlineColor] = useState("");

    const colorIt = (val) => {
        setUnderlineColor(val);
    };

    const openSidebar = () => {
        const sidebarLinks = document.getElementById("listItemsSide");
        const ham = document.getElementById("ham");
        if (sidebarLinks.style.display === "none") {
            sidebarLinks.style.display = "flex";
            ham.src = { cross };
        } else {
            sidebarLinks.style.display = "none";
            ham.src = { menu };
        }
    }


    useEffect(() => {
        let aa = document.body.getBoundingClientRect().top;
        const handleScroll = () => {
            if (document.body.getBoundingClientRect().top > aa) {
                const navbar = document.getElementById("navbarParentContainer");
                navbar.style.display = "block";
                navbar.style.animation = "bringDown 0.2s 0.2s forwards";
            } else {
                const navbar = document.getElementById("navbarParentContainer");
                navbar.style.display = "none";
                navbar.style.animation = "none";
            }
            aa = document.body.getBoundingClientRect().top;
        };


        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div id="navbarParentContainer">
                <div id="navbarContainer">
                    <div id="logoContainer">
                        <div id="logoImgContainer">
                            <a href="../#landingPage">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div id="homeTitle">RECTR</div>
                    </div>
                    <div id="listItems">
                        <button
                            className={`listOfBtns ${underlineColor === "explore" ? "underline" : ""}`}
                            onClick={() => colorIt("explore")}
                        >
                            <a id="navLinks" href="../#landingPage">
                                Explore
                            </a>
                        </button>
                        <button
                            className={`listOfBtns ${underlineColor === "features" ? "underline" : ""}`}
                            onClick={() => colorIt("features")}
                        >
                            <a id="navLinks" href="../#featuresPage">
                                Features
                            </a>
                        </button>
                        <button
                            className={`listOfBtns ${underlineColor === "how" ? "underline" : ""}`}
                            onClick={() => colorIt("how")}
                        >
                            <a id="navLinks" href="../#workPage">
                                How It Works?
                            </a>
                        </button>
                        <button
                            className={`listOfBtns ${underlineColor === "why" ? "underline" : ""}`}
                            onClick={() => colorIt("why")}
                        >
                            <a id="navLinks" href="../#whyUsPage">
                                Why Us?
                            </a>
                        </button>
                    </div>
                    <div id="signUpContainer">
                        <button type="submit" id="getInButton">
                            <a href="../signup">Get In</a>
                        </button>
                        <div id="theJourneyText">The Journey</div>
                    </div>
                </div>
                <div id="sidebar">
                    <div id="logoContainer">
                        <div id="logoImgContainer">
                            <a href="../#landingPage">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div id="homeTitle">RECTR</div>
                    </div>
                    <div id="burgir">
                        <img id="ham" src={menu} alt="" onClick={openSidebar} />
                        <div id="listItemsSide">
                            <button
                                className={`listOfBtns ${underlineColor === "explore" ? "underline" : ""}`}
                                onClick={() => colorIt("explore")}
                            >
                                <a id="navLinks" href="../#landingPage">
                                    Explore
                                </a>
                            </button>
                            <button
                                className={`listOfBtns ${underlineColor === "features" ? "underline" : ""}`}
                                onClick={() => colorIt("features")}
                            >
                                <a id="navLinks" href="../#featuresPage">
                                    Features
                                </a>
                            </button>
                            <button
                                className={`listOfBtns ${underlineColor === "how" ? "underline" : ""}`}
                                onClick={() => colorIt("how")}
                            >
                                <a id="navLinks" href="../#workPage">
                                    How It Works?
                                </a>
                            </button>
                            <button
                                className={`listOfBtns ${underlineColor === "why" ? "underline" : ""}`}
                                onClick={() => colorIt("why")}
                            >
                                <a id="navLinks" href="../#whyUsPage">
                                    Why Us?
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
