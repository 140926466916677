import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Privacypage from "./Pages/Privacypage";
import Redirectpage from "./Pages/Redirectpage";
import AcknowledgementPage from "./Pages/AcknowledgementPage";
import Page404 from "./Pages/Page404";

const MobileView = () => {
  return (
    <div className="center">
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Homepage />} />
          <Route path="/privacy" element={<Privacypage />} />
          <Route path="/redirect" element={<Redirectpage />} />
          <Route path="/ack" element={<AcknowledgementPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default MobileView;
