import React from "react";
import { Link } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import "./Slider.css";

const images = [
  { url: "MobileViewResources/assests/homeSlider/Group1.png" },
  { url: "MobileViewResources/assests/homeSlider/Group2.png" },
  { url: "MobileViewResources/assests/homeSlider/Group3.png" },
  { url: "MobileViewResources/assests/homeSlider/Group4.png" },
];

//  Slider section


const Slider = () => {

  const dismissnotification = () => {
    const notificationbox = document.querySelector(".notificationbox");
    notificationbox.style.display = "none";
  }

  const sliderStyle = {
    width: "100% !important ", 
  };
  return (
    <>
    <div className="notificationbox">
      <div className="imgbox">
      <img onClick={dismissnotification} width="50" height="50" src="https://img.icons8.com/ios/50/cancel.png" alt="cancel"/>
      </div>
      <div className="content">
        <Link to="https://play.google.com/store/apps/details?id=com.rectr.rectrapp" target="_blank">
        <div className="footerdownload">
          <h1>Download Our App</h1>

          <a href="https://play.google.com/store/apps/details?id=com.rectr.rectrapp" target="_blank" ><p>DOWNLOAD RECTR APP </p><img width="30" height="30" src="https://img.icons8.com/fluency/48/google-play-store-new.png" alt="google-play-store-new"/></a>
          
        </div>
        </Link>
      </div>
    </div>
      <h1 id="L1">
        Finding <span>Housing</span>
        <br /> Made Easy.
      </h1>
      <div className="slider">
        <SimpleImageSlider
          width={405}
          height={310}
          images={images}
          showBullets={true}
          navStyle={3}
          navMargin={10}
          navSize={35}
          autoPlay={true}
          slideDuration={1}
          useGPURender={true}
          style={sliderStyle}
          bgColor={"#93B1A6"}
        />
      </div>

      <h3 id="L2">
        Searching houses aren't a struggle any more. Providing best search
        results, we let you select your Host & Tenants too.
      </h3>
    </>
  );
};

export default Slider;
