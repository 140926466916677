import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Slider from "../components/Slider/Slider";
import Body from "../components/Body/Body";

import "./Homepage.css";

const Homepage = () => {
  return (
    <>
      <Navbar />
        <section className="mainsection">
          <Slider />
          <Body />
        </section>
      <Footer />
    </>
  );
};

export default Homepage;
