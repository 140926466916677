import React from "react";
import Privacy from "./../components/Privacy/Privacy";
import Navbar from "./../components/Navbar/Navbar";
import Footer from "./../components/Footer/Footer";


const Privacypage = () => {
  return (
    <>
      <Navbar />
      <Privacy />
      <Footer />
    </>
  );
};

export default Privacypage;
